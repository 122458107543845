@import 'variables';
*{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}

body{
  margin: 0;
  padding: 0;
  background: #FAFAFA;
  font-family:$ibmplexRegular;
  .singleline-clamp{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .not-registered{
    @include elementStyles(13px, #000000, normal, normal, $ibmplexRegular, null, null);
    margin-top: 5px;
    a{
      text-decoration: underline;
    }
  }
  .classes-state-root {
    .MuiDialog-paper{
      max-height: 0px !important;
      margin:0px !important;
    }
  }
  .license-modal{
    .license-title{
      color:#bd10e0;
      .MuiTypography-h6{
        font-weight: 400 !important;
      }
    }
    .licence-description{
      display: flex-centered;
      align-items: center;
      margin:40px 0px 30px 0px;
    }
    .licence-action{
      display: block;
      .licence-action-link{
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .page-not-found{
		padding: 100px 200px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		text-align: center;
		box-shadow: 0 1px 2px rgba(0,0,0,0.3);
		h1{
			font-family: $ibmplexSemiBold;
			font-size: 70px;
			color: #000;
		}
		h4{
			font-family: $ibmplexMedium;
			font-size: 30px;
			color: #000;
		}
		a{
			text-decoration: underline;
		}
	}
  .date-range-picker-popover{
    padding: 15px;
    ul.list-unstyled{
      li{
        margin-bottom: 15px;
      }
    }
    ul.list-inline{
      li{
        margin-right: 15px;
      }
    }
  }
  .date-range-filter{
    clear: both;
    ul{
      float: right;
      border: 1px solid #d7d7d7;
      border-radius: 4px;
      margin-bottom: 10px;
      li{
        cursor: pointer;
        padding: 10px;
        border-left: 1px solid #d7d7d7;
        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
        &.active{
          background: #d7d7d7;
        }
        &:first-child{
          border-left: none;
        }
      }
    }
  }

  .custom-label{
    @include elementStyles($size: 16px, $color: rgba(0, 0, 0, 0.54), $fontFamily: $ibmplexRegular);
  }
  .table-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .error-msg{
      @include elementStyles($size: 12px, $color: #ff0000, $fontFamily: $ibmplexRegular);
  }
  .for-label{
    @include elementStyles($size: 16px, $color: rgba(0,0,0,0.54), $fontFamily: $ibmplexRegular);
    margin-bottom: 6px;
    display: inline-block;
    &.mui-label-type{
      font-family: $ibmplexMedium !important;
      font-weight: 500 !important;
      color: #000;
      font-size: 11px;
    }
  }
  .list-unstyled{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .list-inline{
    list-style: none;
    display: inline-block;
    li.list-inline-item{
      display: inline-block;
      float: left;
    }
  }
  .flex-centered{
    display: flex;
    align-items: center;
  }
  .font{
    font-size: 14px;
  }
  .archive-chip-class{
    background-color: #dfdfdf;
    padding: 3px 5px;
    border-radius: 4px;
  }
  .flex-not-centered{
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before, &:after {
         width: 0;
    }
  }
  .text-center{
    text-align: center;
  }
  .text-left{
    text-align: left;
  }
  .text-right{
    text-align: right;
  }
  .mui-styled-table{
    &.hovered-table{
      tr{
        background: inherit;
        cursor: pointer;
      }
    }
    .actions-cell{
      text-align: right;
      svg{
        cursor: pointer;
      }
    }
    thead{
      background: #f0f2f3;
      tr{
        .MuiTableCell-root{
          padding:8px 15px 8px 6px !important;
        }
      }
      th{
        span{
          @include elementStyles($size: 13px, $color: #6d9bb9, $fontFamily: $ibmplexSemiBold);
          &.MuiTableSortLabel-root{
            &:hover{
              color: #496ac5;
              .MuiTableSortLabel-icon{
                color: #496ac5 !important;
              }
            }
            .MuiTableSortLabel-icon{
              opacity: 0.5 !important;
            }
          }
          &.MuiTableSortLabel-active{
            color: #496ac5;
            .MuiTableSortLabel-icon{
              color: #496ac5 !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    tbody{
      .MuiTableCell-root{
        padding:4px !important;
      }
      tr{
        &:nth-child(odd){
          background: #ebeff6;
        }
        &:nth-child(even){
          background: #f5f7fa;
        }
        &.Mui-selected{
          background: rgba(245, 0, 87, 0.08);
        }
        td{
          word-break: break-word;
        }
      }
    }
  }
  
  .pagination-alignment{
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
  .pagination-custom-field{
    width: 70px;
    margin-right: 8px;
    .MuiOutlinedInput-input{
      padding:5px 0px 8px 4px;
    }  
  }
  .go-buttons{
   background-color:#f7f1f1;
   height: 27px;
   width: 51px;
   border: 1px solid #b4adad;
   border-radius: 3px;
   cursor: pointer;
  }
  @media only screen and (max-width: 1395px){
    .screen-button-size{
    .MuiButton-containedSizeSmall {
      width: 131px;
     }
    }
  }
  .label-value-card{
    p{
      @include elementStyles($size: 14px, $color: #a0a0a0, $fontFamily: $ibmplexRegular);
      margin-bottom: 4px;
    }
    h6{
      @include elementStyles($size: 16px, $color: $blackColor, $fontFamily: $ibmplexRegular);
      word-break: break-word;
    }
  }
  .empty-collection-ui{
    padding: 20px 0;
    text-align: center;
    clear: both;
    img{
      width: 320px;
      margin-bottom: 10px;
    }
    h4{
      @include elementStyles($size: 18px, $color: $blackColor, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
    }
    p{
      @include elementStyles($size: 14px, $color: #c4c4c4, $fontFamily: $ibmplexRegular);
      width: 540px;
      margin: 0 auto;
    }
  }
  .plt-filter-comp{
    padding: 2px 10px 2px 10px;
    border-radius: 0;
    min-height: 30px;
    position: relative;
    clear: both;
    .filter-icon{
      margin-right: 10px;
      position: relative;
      top: -4px;
    }
    .clear-all-filter{
      position: absolute;
      right: 10px;
      top: 8px;
      font-size: 12px;
      border: 1px solid #3f51b5;
      padding: 1px 4px;
      border-radius: 3px;
      cursor: pointer;
      color: #3f51b5;
    }
    .filter-applied{
      padding-right: 72px;
      ul{
        li{
          margin: 0 5px 5px 0;
          padding: 2px 5px 5px 10px;
          background: rgba(0,78,255, 0.2);
          border: 1px solid rgba(101,83,251,.35);
          border-radius: 14px;
          @include elementStyles($size: 12px, $color: #c4c4c4, $fontFamily: $ibmplexRegular);
          .filter-label{
            color: #0b1f8a;
            text-decoration: underline;
            display: inline-block;
            margin-right: 8px;
          }
          .filter-data{
            color: $blackColor;
            font-family: $ibmplexSemiBold;
          }
          .remove-filter{
            cursor: pointer;
            padding-left: 8px;
            position: relative;
            top: 2px;
            svg{
              font-size: 16px;
              color: #ea6363;
            }
          }
        }

      }
    }
  }
  .plt-filter-footer{
    position: fixed;
    bottom: 0px;
    background: #fff;
    border-top: 1px solid #d7d7d7;
    z-index: 99;
    width: 100%;
    padding: 15px 30px;
    button{
      margin-right: 10px;
    }
  }
  .plt-filter-header{
    position: fixed;
    top: 0;
    width: 100%;
    padding: 16px 30px;
    border-bottom: 1px solid #d7d7d7;
    background: #e4e4e4;
    z-index: 99;
    h3{
      @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular);
    }
  }
  .plt-filter-body{
    padding: 60px 30px 80px 30px;
    width: 300px;
    .date-comp{
      margin-top: 15px;
      h4{
        @include elementStyles($size: 16px, $color: rgba(0, 0, 0, 0.54), $fontFamily: $ibmplexRegular);
      }
    }
  }
  .page-loader{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.1);
    overflow: hidden;
    cursor: not-allowed;
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img{
        width: 100px;
      }
    }
  }
  .delete-record-modal{
    text-align: center;
    padding: 30px;
    h4{
      @include elementStyles($size: 20px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 40px 0);
    }
    .actions-cta{
      button{
        margin-left: 10px;
        &.first-child{
          margin-left: 0;
        }
      }
    }
  }
  .comment-input__suggestions{
    max-height: 400px;
    overflow: auto;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.15) !important;
    > div{
      border: 1px solid rgba(0,0,0,0.15) !important;
    }
    .list{
      background: #fff;
      
      font-size: 14px;
    }
    .item{
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0,0,0,0.15);
      // &::fo {
      //   backgroundColor: #cee4e5
      // }
    }
  }
  .comments-wrapper{
    width: 440px;
    .comment-input-disabled{
      height: auto;
      border: none !important;
      textarea{
        border: none !important;
      }
    }
    .comment-input{
      min-height: 80px;
      border: 1px solid #d7d7d7;
      border-radius: 4px;
      margin-bottom: 5px;
      textarea{
        padding: 8px;
      }
    }
    .comments-header{
      padding:10px 15px;
      border-bottom: 1px solid #d7d7d7;
      h2{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000);        
      }
    }
    .comments-body{
      padding: 0 15px;
      .comment-card{
        border-bottom: 1px solid #d7d7d7;
        padding: 10px 0;
        .comment-user-info{
          li{
            margin-right: 5px;
            span{
              @include elementStyles($size: 12px, $fontFamily: $ibmplexRegular, $color: rgba(0, 0, 0, 0.54));
            }
            svg{
              font-size: 15px;
            }
          }
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
    .comments-footer{
      border-top: 1px solid #d7d7d7;
      padding: 5px 15px;
    }
  }
  .file-preview-section{
    .header-section{
      box-shadow: 0 2px 1px rgba(0,0,0,0.2);
      margin-bottom: 10px;
      .logo-row{
        padding: 10px;
        border-bottom: 1px solid #f1f1f1;
        img{
          width: 32px;
        }
      }
      .file-information{
        padding: 0 10px;
        h4{
          @include elementStyles($size: 15px, $color: rgba(0, 0, 0, 1), $fontFamily: $ibmplexRegular);
        }
        ul.list-inline{
          float: right;
          li{
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .not-supported-preview{
      padding-top: 120px;
      img{
        margin-bottom: 20px;
      }
      h4{
        @include elementStyles($size: 18px, $color: rgba(0, 0, 0, 1), $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
      }
      p{
        @include elementStyles($size: 18px, $color: rgba(0, 0, 0, 0.8), $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
      }
    }
  }
  .search-card{
    align-items: center; padding: 5px 0px; justify-content: right ;position: absolute; right:16px;
  }
  .search-width{
     width:375px;
  }
  .application-card{
    background: #7d7561;
    padding: 20px;
    border-radius: 4px;
    height: 200px;
    position: relative;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    .application-info{
      margin-bottom: 20px;
      h3{
        margin-bottom: 20px;
        img{
          margin-right: 15px;
        }
        span{
          text-shadow: 0 2px 2px rgba(0,0,0,0.2);
          @include elementStyles($size: 20px, $color: #ffffff, $fontFamily: $ibmplexMedium);
        }
      }
      p{
        @include elementStyles($size: 16px, $color: #ffffff, $fontFamily: $ibmplexRegular);
      }
    }
    p.restrict-access{
      position: absolute;
      left: 20px;
      bottom: 20px;
      @include elementStyles($size: 13px, $color: #fff03b, $fontFamily: $ibmplexRegular);

    }
    button{
      position: absolute;
      left: 20px;
      bottom: 10px;
      @include elementStyles($size: 18px, $color: #ffffff, $fontFamily: $ibmplexMedium);
      border-color:#ffffff;
    }
  }
  .faqs-list{
    h2.title{
      @include elementStyles($size: 16px, $color: #bd10e0, $fontFamily: $ibmplexRegular, $margin:15px 0 10px 0);
    }
    .content{
      @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
    }
  }
  .whats-new-timeline{
    ul{
      li{
        padding: 15px 0;
        border-bottom: 1px solid #d7d7d7;
        span{
          @include elementStyles($size: 13px, $color: #c4c4c4, $fontFamily: $ibmplexLight);
        }
        h4{
          @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular, $margin:0 0 16px 0);
        }
        .content{

        }
      }
    }
  }
  .navigation_modal{
    cursor: pointer;
  }
}
.edit-revision-history{
  margin-bottom: 15px;
  padding: 20px 20px 20px 30px;
  h6,p{
    font-size: 12px !important;
    margin-bottom: 5px;
  }
  ul{
    border-left: 1px solid #8b36e1;
    padding-left: 20px;

    li{
      position: relative;
      .timeline-icon{
        position: absolute;
        left: -35px;
        top: 0;
        z-index: 10;
        img{
          width: 27px;
          background: #ffffff;
        }
      }

      background: #fff;
      box-shadow: 0 4px 16px 0 #e6e6e6;
      border-radius: 6px;
      padding: 15px;
      margin-bottom: 20px;
      // overflow: hidden;
      table{
        width: 100%;
      }
    }
  }
}
.edit-detail-table{
  overflow: auto;
  table{
    width: 100%;
    border-collapse: separate;
    thead{
      th{
        padding:4px 20px;
        font-family: $ibmplexRegular;
        font-size:12px;
        color:#a0a0a0;
        border:1px solid #ccc;
        width:80px !important;
      }
    }
    tbody{
      th{
        padding:4px 20px;
        font-family: $ibmplexRegular;
        font-size:12px;
        color:#a0a0a0;
        border:1px solid #ccc;
        width:80px !important;
      }
      td{
        width:80px !important;
        padding:4px 20px;
        font-family: $ibmplexRegular;
        font-size:12px;
        color:#000000;
        border:1px solid #ccc;
        width:80px !important;
        span{
          color:#000000 !important;
        }

      }
    }
  }
}
.edit-revision-history {
  // width: auto !important;
  h4 {
    .mobile-margin {
      margin-left: 10px !important;
      button {
        .MuiButton-label {
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}
.deployment-info{
  background: #fff1b6;
  padding: 5px;
  border: 1px solid #a78701;
  border-radius: 4px;
  margin-bottom: 10px;
  // display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  span{
    color: #a78701;
    font-size: 12px;
    font-family: $ibmplexSemiBold;
    h6{
      color:#478ddc;
      font-size: 12px;
      display: inline;
    }
  }
  img{
    float: right;
    cursor: pointer;
    color: #ff0000;
    margin-top: 2px;
  }
}
.read-more-pop-up{
  .chp-cls{
    margin-bottom: 3px;
    margin-right: 3px;
    height: 23px !important;
    padding: 3px 5px !important;
  }
}
@media only screen and (max-width: 600px){
  .filter-ui-component .filter-result{
    ul{
      li{
        .MuiChip-root{
          font-size: 11px;
          max-width: 190px;
        }
      }
    }
  }
  .mobile-rc-tree-paper{
    max-height: 75vh!important;
    overflow: auto;
    .rc-tree{
      font-size: 13px;
    }
  }
  .MuiDialog-paper{
    .MuiDialogActions-root{
      button{
        //font-size: 11px;
      }
    }
  }
  .mui-styled-table tbody tr td{
    word-break: normal!important;
  }
  .list-inline li.list-inline-item{
    .mobile-export-btn{
      font-size: 13px;
    }
  }
  .MuiDialog-paper .MuiDialogContent-root .dropzone-area
    p{
      font-size: 14px;
    }
    .MuiDropzoneArea-icon{
      width: 35px!important;
      height: 35px!important;
    }
  .zsiq_floatmain{
    display: none !important;
  }
  .highcharts-button{
    display: none;
  }
  .file-preview-section{
    .header-section{
      .file-information{
        h4{
          font-size: 13px;
          }
          ul{
            &.list-inline{
              float: left!important;
              svg{
                font-size: 20px;
              }
              li{
                // margin-left: 8px;
                button{
                  font-size: 11px;
                }
                &.comments-hidden-mobile, &.linked-issue-hidden-mobile, &.add-issue-hidden-mobile, &.unlinked-issue-hidden-mobile{
                  display: none;
                }
              }
            }
        }
      }
    }
  }
}
.checklist-label-width{
  width: fit-content;
}
@media only screen and (min-width: 600px) and (max-width: 960px){}
@media only screen and (max-width: 932px) and (min-width: 320px) {
  .comments-wrapper{
  width:unset !important;
  }
 // .file-preview-section{
  .search-card {
    align-items: center; padding: 5px 0px; justify-content: right ;right:16px;position: unset !important;
  }
  .checklist-label-width{
    width: 200px !important;
  }
  .serch-content-h4{
    word-break:break-word;
    width:215px
  }
//}
}
